import React, { Component, PropTypes } from 'react';
import { Card, CardImg, CardText, CardBody,CardTitle, CardSubtitle, Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';
import Popup from 'react-popup';

class ImportButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      import: this.props.import,
      importing: (this.props.import.status && this.props.import.status == 'queued') || false,
      imported: (this.props.import.status && this.props.import.status == 'processed') || false,
      queued: (this.props.import.status && this.props.import.status == 'queued') || false,
      modal: false,
      departments: this.props.departments,
      categories: this.props.categories,
      states: this.props.states,
      zones: this.props.zones,
      brands: this.props.brands,
      department: {id:null,name:'Elige un departamento'},
      category: {id:null,name:'Elige una categoría'},
      state: {id:null,name:'Elige una provincia'},
      zone:{id:null,name:'Elige una zona'},
      brand_ids: []
    };

    this.handleClick = this.handleClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleBrandOnChange = this.handleBrandOnChange.bind(this);
  }

  toggleModal() {
    const self = this;

    this.setState({
      modal: !this.state.modal
    });
  }

  handleBrandOnChange(e) {
    let new_brands = this.state.brand_ids;

    if(e.target.checked){      
      new_brands.push(parseInt(e.target.value));
    }else{
      const index = new_brands.indexOf(parseInt(e.target.value));

      if (index > -1) {
        new_brands.splice(index, 1);        
      }      
    }

    this.setState({brand_ids:new_brands});
  }  

  handleClick() {
    const self = this;

    self.setState({importing:true});

    $.ajax({
      dataType:'json',
      url: `/imports/${this.state.import.id}/queue`,
      method: 'POST',
      data: {category_id:this.state.category.id,tenant_id:this.state.department.id,state_id:this.state.state.id,zone_id:this.state.zone.id,brand_ids:this.state.brand_ids},
      success: function(response){
        console.log(response);

        if (response.result.status == 'queued'){          
          self.setState({importing:true,queued:true},function(){
            const timer = setInterval(function() {
              $.ajax({
                dataType:'json',
                url: `/imports/${self.state.import.id}`,
                success: function(response){                  
                  if(response.status == 'processed'){
                    clearInterval(timer);
                    self.setState({imported:true, queued:false, importing:false},function(){                      
                      setTimeout(function(){
                        self.toggleModal();

                        setTimeout(function(){
                          window.location.reload();
                        },1000);  
                      },2000);
                    });
                  }else if (response.status == 'fail'){
                    clearInterval(timer);
                    self.setState({imported:false, queued:false, importing:false}, function(){                      
                      self.toggleModal();
                      Popup.alert(<p>Hubo un error al intentar importar el archivo</p>, 'Atención!');
                    });                    
                  }
                }
              })
            }, 15000);           
          });          
        }else{
          self.setState({importing:false});
          Popup.alert(<React.Fragment><p>Hubo un error al intentar importar el archivo</p></React.Fragment>,'Atención!');
        }

      },
      error: function(error){
        console.log(error);
        self.setState({importing:false});

        Popup.alert(<React.Fragment><p>Hubo un error al intentar importar el archivo</p></React.Fragment>,'Atención!');
      }
    });
  }

  handleDepartmentChange(e){
    const index = e.nativeEvent.target.selectedIndex;
    const new_value = e.target.value;
    const self = this;

    this.setState({department:{id:new_value,name:e.nativeEvent.target[index].text}})
  }

  handleCategoryChange(e){
    const index = e.nativeEvent.target.selectedIndex;
    const new_value = e.target.value;
    const self = this;

    this.setState({category:{id:new_value,name:e.nativeEvent.target[index].text}})
  }  

  handleStateChange(e){
    const index = e.nativeEvent.target.selectedIndex;
    const new_value = e.target.value;
    const self = this;

    this.setState({state:{id:new_value,name:e.nativeEvent.target[index].text}})
  }  

  handleZoneChange(e){
    const index = e.nativeEvent.target.selectedIndex;
    const new_value = e.target.value;
    const self = this;

    this.setState({zone:{id:new_value,name:e.nativeEvent.target[index].text}})
  }    

  render() {
    const self = this;

    let generating_report_html = []
    let imported_message_html = []
    let card_body = []
    let launcher_button = []

    const department_formatted_value = this.state.department.name
    const category_formatted_value = this.state.category.name
    const state_formatted_value = this.state.state.name
    const zone_formatted_value = this.state.zone.name

    const savingButton = this.state.importing ? <Button color="secondary" disabled><i className="fa fa-spin fa-spinner"></i> Procesando</Button> : <Button color="primary" onClick={this.handleClick}>Importar</Button>
    const cancelButton = this.state.importing ? <Button color="secondary" disabled>Cancelar</Button> : <Button color="secondary" onClick={this.toggleModal}>Cancelar</Button>

    const brands_html = this.state.brands.map(function(obj){
        return(
              <React.Fragment key={`brand-checkbox-fragment-${obj.id}`}>
                <div className="clearbox">
                  <label>                
                    <input
                      name={`import_brand_${obj.id}`}
                      type="checkbox"
                      value={obj.id}
                      onChange={self.handleBrandOnChange}
                    />
                    &nbsp; {obj.name}
                  </label>   
                </div>
              </React.Fragment>     
          )
      });

    const department_options_html = this.state.departments.map(function(department){
      return(
          <option key={`department-config-${department.id}`} value={department.id} text={department.name}>{department.name}</option>
        )
    });

    const category_options_html = this.state.categories.map(function(category){
      return(
          <option key={`category-config-${category.id}`} value={category.id} text={category.name}>{category.name}</option>
        )
    });

    const state_options_html = this.state.states.map(function(state){
      return(
          <option key={`state-config-${state.id}`} value={state.id} text={state.name}>{state.name}</option>
        )
    });        

    const zone_options_html = this.state.zones.map(function(zone){
      return(
          <option key={`zone-config-${zone.id}`} value={zone.id} text={zone.name}>{zone.name}</option>
        )
    });            

    if (this.state.importing) {
      generating_report_html = <React.Fragment>
            <div className="text-center mb-4">
              <i className="fa fa-spinner fa-spin fa-3x text-primary"></i>
            </div>
          </React.Fragment>

      launcher_button = <Button block outline color="dark" size="sm" onClick={this.toggleModal}><i className="fa fa-spin fa-spinner"></i> Importando..</Button>          
    }else{
      launcher_button = <Button block outline color="dark" size="sm" onClick={this.toggleModal}><i className="fa fa-sync"></i> Importar</Button>          
    }

    if (this.state.queued) {
      imported_message_html = <div className='text-center  animated flipInX'><i className='fa fa-spin fa-spinner fa-5x text-info'></i><h5><br/>La importación a comenzado! Por favor no cierres esta pestaña.</h5></div>
      card_body = <React.Fragment>{imported_message_html}</React.Fragment>
    }else if (this.state.imported) {
      imported_message_html = <div className='text-center  animated flipInX'><i className='fa fa-check-circle fa-5x text-success'></i><h5><br/>La importación a finalizado!</h5></div>
      card_body = <React.Fragment>{imported_message_html}</React.Fragment>
    }else{
      card_body = <React.Fragment>
                    <p className="alert alert-info">
                      A continuación puedes elegir las siguientes configuraciones, si no están presentes en el archivo CSV que cargaste. Esta configuración reemplazará los campos del mismo nombre provenientes del CSV original.
                    </p>
                    <div className="form-group">
                      <label>Departamento</label>
                      <select className="form-control" onChange={this.handleDepartmentChange} style={{display:'inline',width:'100%'}}>
                        <option value="" text="Selecciona uno">Selecciona uno</option>
                        {department_options_html}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Categoría</label>
                      <select className="form-control" onChange={this.handleCategoryChange} style={{display:'inline',width:'100%'}}>
                        <option value="" text="Selecciona uno">Selecciona una</option>
                        {category_options_html}
                      </select> 
                    </div>
                    <div className="form-group">
                      <label>Provincia</label>
                      <select className="form-control" onChange={this.handleStateChange} style={{display:'inline',width:'100%'}}>
                        <option value="" text="Selecciona uno">Selecciona una</option>
                        {state_options_html}
                      </select> 
                    </div>
                    <div className="form-group">
                      <label>Zona</label>
                      <select className="form-control" onChange={this.handleZoneChange} style={{display:'inline',width:'100%'}}>
                        <option value="" text="Selecciona uno">Selecciona una</option>
                        {zone_options_html}
                      </select>                   
                    </div>
                    <div className="form-group">
                      <label>Marcas</label>
                      <Card>
                        <CardBody>{brands_html}</CardBody>
                      </Card>
                    </div>                    
                  </React.Fragment>  
    }

    return (
        <React.Fragment>
          {launcher_button}
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="sm-604">
            <ModalHeader toggle={this.toggleModal}>Configurar</ModalHeader>
            <ModalBody>
              <div>
                {card_body}                           
              </div>                            
            </ModalBody>
            <ModalFooter>
              {savingButton}{' '}
              {cancelButton}
            </ModalFooter>            
          </Modal>            
        </React.Fragment>
      )    
  }
}

export default ImportButton;