import React, { Component, useState } from 'react';
import AddToCart from '../cart/actions/AddToCart';
import { Tooltip } from "reactstrap";

const TooltipItem = props => {
  const { placement, content, id, status_icon } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <i className={`far fa-${status_icon}`} id={"Tooltip-" + id}></i>
      <Tooltip
        placement={placement}
        isOpen={tooltipOpen}
        target={"Tooltip-" + id}
        toggle={toggle}
      >
      {content}
      </Tooltip>
    </span>
  );
};

class ProductItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let show_add_to_cart = (this.props.product.stock_quantity > 0 || !this.props.product.use_stock)
    let in_stock_html = show_add_to_cart ? 'En Stock' : 'Sin Stock';
    let add_to_cart = <AddToCart product={this.props.product} cart={this.props.active_cart} checkout_url={this.props.checkout_url} add_products_url={this.props.add_products_url}/>

    let status_icon = 'check-circle';
    let status_color = 'success';
    let image_src = '/images/products/default-no-image.svg';
    let product_currency = "$";
    let tax_text = "";

    if (!this.props.product.use_stock || (this.props.product.use_stock && this.props.product.stock_quantity >= this.props.product.min_stock)){
      in_stock_html = 'En Stock'
    }else{
      in_stock_html = 'Sin Stock'
      show_add_to_cart = false
      add_to_cart = <h5 className="text-muted rounded border p-2 text-center mb-0">{in_stock_html}</h5>
      status_color = 'warning';
      status_icon = 'times-circle';
    }

    if(this.props.product.images.length > 0){
      image_src = this.props.product.images[0].src;
    }

    if(this.props.product.currency){
      if(this.props.product.currency != '' && this.props.product.currency != 'ARS'){
        product_currency = this.props.product.currency;
      }
    }

    if(this.props.product.use_tax){
      tax_text = <small className="ml-1">+ IVA</small>;
    }

    return (
          <div className="col-md-3 mb-3">
            <div className="card card-products-list shadow-sm mx-0 h-100">
              <div className="card-body p-2">
                <div className="bg-light text-center py-2 p-1 mb-3 rounded">
                  <a href={`${this.props.product_path}${this.props.product.id}`}>
                    <img src={image_src} className="max-height-360 img-fit"/>
                  </a>
                </div>
                <h5>
                  <a href={`${this.props.product_path}${this.props.product.id}`}>
                    {this.props.product.title}
                  </a>
                </h5>
                <div className="mt-3 mb-3 d-flex product-details">
                  <h4 className="font-weight-light mb-0 product-price"><strong>{product_currency}</strong><span className="ml-1">{parseFloat(this.props.product.price).toFixed(2)}</span>{tax_text}</h4>
                  <div className={`p-1 ml-auto text-${status_color} product-stock-avail`}>
                    <TooltipItem key={this.props.product.id} placement={'top'} id={this.props.product.id} content={in_stock_html} status_icon={status_icon} />
                  </div>
                </div>
              </div>
              <div className="card-footer bg-white">
                <div className="w-100">
                  {add_to_cart}
                </div>
              </div>
            </div>
          </div>
      )
  }
}

export default ProductItem;
