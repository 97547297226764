import React, { Component, PropTypes } from 'react';
import ReactDataSheet from 'react-datasheet';


class DataSheet extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      grid: this.props.grid,
      columns: this.props.headers
    }
  }
  
  ComponentDidMount(){
    console.log(this.state.grid)
  }

  handleSelectedCheckbox(){}

  render () {
    const self = this;

    return (
      <ReactDataSheet
        data={this.state.grid}
        valueRenderer={(cell) => cell.value}
        sheetRenderer={props => (
          <table className={props.className + ' table-bordered'} cellspacing="4" cellpadding="4">
              <thead>
                  <tr>
                      <th className='action-cell' />
                      {self.state.columns.map(col => (<th>{col.name}</th>))}
                  </tr>
              </thead>
              <tbody>
                  {props.children}
              </tbody>
          </table>
        )}
        rowRenderer={props => (
          <tr>
              <td className='action-cell'>
                  <input 
                      type='checkbox' 
                      checked={self.handleSelectedCheckbox(props.row)}
                  />
              </td>
              {props.children}
          </tr>
        )}        
        onCellsChanged={changes => {
          const grid = this.state.grid.map(row => [...row])
          changes.forEach(({cell, row, col, value}) => {
            grid[row][col] = {...grid[row][col], value}
          })
          this.setState({grid})
        }}
      />
    )
  }
}

export default DataSheet;