import React, { Component, PropTypes } from 'react';
import ProductItem from './ProductItem';
import Popup from 'react-popup';
import { Button } from 'reactstrap';


const addUrlParam = function(search, key, val){
  var newParam = key + '=' + val,
      params = '?' + newParam;

  // If the "search" string exists, then build params from it
  if (search) {
    // Try to replace an existance instance
    params = search.replace(new RegExp('([?&])' + key + '[^&]*'), '$1' + newParam);

    // If nothing was replaced, then add the new param to the end
    if (params === search) {
      params += '&' + newParam;
    }
  }

  return params;
};

class ProductsIndex extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      checkout_url: this.props.checkout_url,
      active_cart: this.props.cart,
      categories: this.props.categories,
      category: this.props.filter ? this.props.filter.category : '',
      loading: true,
      products: [],
      query: '',
      message: '',
      limit: 16,
      page: 1,
      totalPages: 0,
      totalResults: 0,
      loadMoreEnabled: true,
      reset: false
    };

    this.loadProducts = this.loadProducts.bind(this);
    this.handleOnInputChange = this.handleOnInputChange.bind(this);
    this.handleOnButtonClick = this.handleOnButtonClick.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadProducts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

	handleOnInputChange( event ) {
    const query = event.target.value;
    
		if ( query ) {
			this.setState( { query: query } );
		}else{
      this.setState( { query: '' } );
    }
	}

  handleOnButtonClick() {
    const self = this;
    
    this.setState( { products: [], page: 1 },
      function () {
        self.loadProducts();
      }
    )  
  }

  handleOnKeyDown( event ) {
    const self = this;

    if (event.key === 'Enter') {
      this.setState( { products: [], page: 1 },
        function () {
          self.loadProducts();
        }
      )            
    }
  }  

  handleCategoryChange( event ){
    const self = this;

    const new_value = event.target.value;
    const current_query = this.state.query;

    this.setState( { category: new_value, products: [], page: 1, query: current_query },
        function () {
          self.loadProducts();
        }      
      )
  }

  handlePagination(){
    const self = this;

    this.setState( { page: (this.state.page + 1) },
      function () {
        self.loadProducts();
      }
    )
  }

  loadProducts() {
    const self = this;
    const {query, category} = this.state;

    self.setState({loading:true})

    let productsUrl = this.props.products_url
    let disableLoadMore = false

    $.ajax({
      dataType:'json',
      url: productsUrl,
      method: 'GET',
      data: { filter: { limit: self.state.limit, page: self.state.page, category: category }, q: query },
      success: function(response, textStatus, xhr){
        disableLoadMore = (self.state.page == response.products.pagination.total_pages)

        self._isMounted && self.setState({
          products: self.state.products.concat(response.products.data),
          loading: false,
          loadMoreEnabled: !disableLoadMore}, function(){
            self.sendPostMessage();
          });
      },
      error: function(response){
        Popup.alert(<React.Fragment><p>Ha ocurrido un error. Por favor intenta recargando la página. Gracias!</p></React.Fragment>,'Atención!');
        self._isMounted && self.setState({loading: false});
      }
    });
  }

  sendPostMessage() {
    if (parent) {
      const parent_window = parent;    
      const body = document.body,
            html = document.documentElement;
    
      const iframe_height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
      const obj = {
        LEPTON_MESSAGE: true,
        CONTENT_LOADED: true,
        CONTENT_HEIGHT: iframe_height + 'px'
      }
    
      parent_window.postMessage(obj, '*');
    }
  }

  render() {    
    
    const { query, categories, category } = this.state;
    const active_cart = this.props.active_cart
    const checkout_url = this.props.checkout_url    
    const add_products_url = this.props.add_products_url
    const product_path = this.props.product_path
    const all_categories_html = [<option key='category-0' value=''>Todo</option>]

    let loadMoreButton = []
    let products_html = []

    const categories_html = categories.map(function(c){
      return(
        <option key={c.slug} value={c.slug} selected={category == c.slug}>{c.name}</option>
      )
    }); 
    
    const final_categories_html = all_categories_html.concat(categories_html);

    if (this.state.products.length > 0){
      products_html = this.state.products.map(function(product){
        return(
            <ProductItem key={`shop-product-${product.id}`} product_path={product_path} product={product} active_cart={active_cart} checkout_url={checkout_url} add_products_url={add_products_url}/>
          )
      });
    }

    if (this.state.loading) {
      loadMoreButton = <div className="text-center w-100 mt-5 m-auto">
          <div className="loading-spinner">
            <div className="lds-ellipsis muted"><div></div><div></div><div></div><div></div></div>    
          </div></div>
    }else{
      if (this.state.products.length > 0){
        products_html.concat(this.state.products.map(function(product){
          return(
              <ProductItem key={`shop-product-${product.id}`} product={product} product_path={product_path} active_cart={active_cart} checkout_url={checkout_url} add_products_url={add_products_url}/>
            )
        }));
      }else{
        products_html = <div className="col-lg-12">
                          <div className="py-4 text-center text-muted">
                            <i className="fa fa-search fa-4x mb-3"></i>
                            <br/>
                            <h5>No hay productos para mostrar.</h5>
                          </div>
                        </div>
      }

      if(this.state.loadMoreEnabled && this.state.products.length > 0){
        loadMoreButton = <div className="col-12">
                            <Button outline className="rounded-pill" color="primary" size="lg" block onClick={this.handlePagination}>
                              Cargar Más Productos
                            </Button>
                          </div>
      }
    }

    const formatted_category_value = this.state.category ? this.state.category : 'Categoría'

    return (
        <React.Fragment>
          <div className="row m-0 animated fadeIn">
            <div className="col-lg-12 col-12">
              <div className="form-group">
                <div className="mb-3 input-group">
                  <input
                    type="text"
                    name="query"
                    value={ query }
                    id="search-input"
                    placeholder="Buscar..."
                    className="form-control w-50"
                    onChange={this.handleOnInputChange}
                    onKeyDown={this.handleOnKeyDown}
                  />                  
                  <select id="inputGroupCategorySelect" className="custom-select rounded-0" onChange={this.handleCategoryChange}>
                    {final_categories_html}
                  </select>
                  <div className="input-group-append">
                    <Button onClick={this.handleOnButtonClick}>
                      <i className="fa fa-search form-control-feedback"></i>
                    </Button>                   
                  </div>
                </div>
              </div>                          
            </div>
            <div className="col-lg-12 col-12">
              <div className="card-deck row">
                {products_html}
              </div>
              <div className="row">
                {loadMoreButton}
              </div>                
            </div>
          </div>
          <Popup defaultOk="Aceptar" defaultCancel="Cancelar"/>
        </React.Fragment>
      )
  }
}

export default ProductsIndex;