import React, { Component, PropTypes } from 'react';
import { Card, CardImg, CardText, CardBody,CardTitle, CardSubtitle, Button, Alert } from 'reactstrap';
import Popup from 'react-popup';

class AddToCart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: this.props.product,
      cart: this.props.cart,
      checkout_url: this.props.checkout_url,
      add_products_url: this.props.add_products_url,
      saving_string: 'Agregar',
      saving:false,
      saved:false,
      quantity: 1,
      alert_visible: false
    };

    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.addProductToCart = this.addProductToCart.bind(this);
    this.onDismissAlert = this.onDismissAlert.bind(this);
  }

  onDismissAlert() {
    this.setState({ alert_visible: false });
  }

  addProductToCart() {
    const self = this;

    self.setState({saving_string:'Guardando..',saving:true})

    $.ajax({
      dataType:'json',
      url: self.state.add_products_url,
      data:{cart_item: {cart_id: this.state.cart.id, product_id: this.state.product.id, quantity: this.state.quantity}},
      method: 'POST',
      success: function(response, textStatus, xhr){
        self.setState({saving_string:'Agregar',saving:false,saved:true,alert_visible:true},
          function(){
            Popup.create({
              title: 'Listo!',
              content: 'Producto agregado al carrito con éxito!',
              buttons: {
                right: [
                  {
                    text: 'Finalizar Compra',
                    className: 'primary',
                    action: function () {
                      Turbolinks.visit(self.state.checkout_url)
                    }
                  },                  
                  {
                      text: 'Seguir Comprando',
                      className: 'muted',
                      action: function () {
                        Popup.close();
                      }
                  }             
                ]
              }
          });            
          }
        );          
      },
      error: function(response){
        Popup.alert(<React.Fragment><p>Ha ocurrido un error. Por favor verifica que hayas selecciado un producto y una cantidad. Gracias!</p></React.Fragment>,'Atención!');
        self.setState({saving_string:'Guardar',saving:false,saved:false});
      }
    });
  }

  handleQuantityChange(e){
    const new_value = e.target.value;
    const self = this;

    this.setState({quantity:new_value})
  }

  render() {
    let values = []

    if (this.state.product.stock_quantity > this.state.product.available_quantity || !this.state.product.use_stock) {
      values = Array.from(Array(this.state.product.available_quantity).keys())
    }else{
      values = Array.from(Array(this.state.product.stock_quantity).keys())
    }
    
    let delivery_status_html = <i className="fa fa-cart-plus"></i>;

    const quantity_html = values.map(function(val){
      val += 1
      
      return(
        <option key={val} value={val}>{val}</option>
      )
    });    

    const formatted_quantity_value = this.state.quantity ? this.state.quantity : 'Elige una cantidad'

    if (this.state.saving) {
      delivery_status_html = <i className="fa fa-spinner fa-spin text-light"></i>
    }

    return (
        <React.Fragment>
          <div className="row">
            <div className="col-lg-12 px-1">
              <div className="input-group">
                <select id="inputGroupQtySelect" className="form-control" value={formatted_quantity_value} onChange={this.handleQuantityChange}>
                  {quantity_html}
                </select>
                <div className="input-group-append">
                  <Button color="primary" onClick={this.addProductToCart}>{delivery_status_html}</Button>
                </div>                
              </div>              
            </div>
          </div>
          <Popup defaultOk="Aceptar" defaultCancel="Cancelar"/>
        </React.Fragment>
      )
  }
}

export default AddToCart;