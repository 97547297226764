import './bootstrap_custom.js';
import '../utils/multiselect/multiselect.bundle.js';
import '../utils/utils.js';
import '../utils/trix.config.js';

import 'turbolinks-animate';

import ReactOnRails from 'react-on-rails';
import Turbolinks from "turbolinks";
import Rails from 'rails-ujs';
import Popup from 'react-popup';

import '@coreui/coreui';
import "chartkick/chart.js"

require("@rails/actiontext")

import ekkoLightbox from "ekko-lightbox";

import * as ActiveStorage from "activestorage";

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import ImportButton from '../components/imports/ImportButton.jsx';
import DataSheet from '../components/datasheets/DataSheet.jsx';
import AddToCart from '../components/cart/actions/AddToCart.jsx';
import ProductsIndex from '../components/products/ProductsIndex.jsx';
import DatesPicker from '../components/charts/DatesPicker.jsx';

// Stimulus Controllers
const application = Application.start();
const context = require.context("controllers", true, /.js$/);

application.load(definitionsFromContext(context));

Rails.start();
ActiveStorage.start();
Turbolinks.start();

window.$ = window.jQuery = require('jquery');

ReactOnRails.setOptions({
  traceTurbolinks: true,
});

ReactOnRails.register({
  Popup,
  ImportButton,
  DataSheet,
  AddToCart,
  ProductsIndex,
  DatesPicker
});