export const translations = { 
  es: {
    bold: "Negrita",
    bullets: "Lista",
    byte: "Byte",
    bytes: "Bytes",
    captionPlaceholder: "Añadir título",
    code: "Código",
    heading1: "Encabezado 1",
    indent: "Indentar",
    italic: "Itálica",
    link: "Link",
    numbers: "Números",
    outdent: "Reducir Intentación",
    quote: "Citar",
    redo: "Rehacer",
    remove: "Eliminar",
    strike: "Tachado",
    undo: "Deshacer",
    unlink: "Quitar Enlace",
    url: "URL",
    urlPlaceholder: "URL...",
    GB: "GB",
    KB: "KB",
    MB: "MB",
    PB: "PB",
    TB: "TB"
  }
};
