import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Popup from 'react-popup';

import DayPicker, { DateUtils } from 'react-day-picker';

import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment'
import 'moment/locale/es';
import Turbolinks from 'turbolinks';

class DatesPicker extends Component {
  static defaultProps = {
    numberOfMonths: 2,
  };

  constructor(props) {
    super(props);

    this.state = {
      saving:(this.props.report && (this.props.report.status == 'queued' || this.props.report.status == 'updating')),
      saved:false,
      from:this.props.from ? new Date(this.props.from) : undefined,
      to:this.props.to ? new Date(this.props.to) : undefined,
      error: undefined,
      modal: false,
    };

    this.handleDayClick = this.handleDayClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);

    this.submitDates = this.submitDates.bind(this);
  }

  toggleModal() {
    const self = this;

    this.setState({
      modal: !this.state.modal,
    });
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleResetClick() {
    this.setState({from:undefined,to:undefined});
  }

  submitDates() {
    const self = this;

    try {
      if(this.state.from && this.state.to){
        this.setState({saving:true});
        Turbolinks.visit('/dashboard?date_start=' + this.formatDate(this.state.from) + '&date_end=' + this.formatDate(this.state.to) );
      }else{      
        Popup.alert(<React.Fragment><p>Por favor ingresa las fechas de inicio y final. Gracias!</p></React.Fragment>,'Atención!');
        return false;
      } 
    }
    catch(err) {
      Popup.alert(<React.Fragment><p>Uh Oh! Ha ocurrido un error al enviar los datos. Por favor reviza que estén todos los campos. Gracias!</p></React.Fragment>,'Atención!');
      console.log(err.message);
      return false;      
    }

  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  render() {
    const self = this;
    
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    let savingButton = this.state.saving ? <Button color="secondary" disabled>Actualizando fechas...</Button> : <Button color="primary" onClick={this.submitDates}>Actualizar</Button>
    let cancelButton = <Button color="secondary" onClick={this.toggleModal}>Cancelar</Button>

    const card_body = <React.Fragment>
                  <h5 className="initialism"><i className="fa fa-calendar"></i> Intervalo de tiempo</h5>
                  <hr/>
                  <div className="form-group">                    
                    <p className="alert alert-info">
                      {!from && !to && 'Por favor selecciona el primer día.'}
                      {from && !to && 'Por favor selecciona el último día.'}
                      {from &&
                        to &&
                        `Seleccionaste desde ${from.toLocaleDateString('en-GB')} hasta
                            ${to.toLocaleDateString('en-GB')}`}{' '}
                      {from &&
                        to && (
                          <Button outline color="info" size="sm" onClick={this.handleResetClick}>
                            Reiniciar
                          </Button>
                        )}
                    </p>                                       
                    <div className="form-group">
                      <DayPicker
                        className="Selectable"
                        numberOfMonths={2}
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick}
                        localeUtils={MomentLocaleUtils}
                        locale='es'
                      />
                    </div>
                  </div>
                </React.Fragment> 

    return (
        <div>
          <Button block outline color="dark" size="sm" onClick={this.toggleModal}>Seleccionar Fechas</Button>
          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="sm-604">
            <ModalHeader toggle={this.toggleModal}>Seleccionar Fechas</ModalHeader>
            <ModalBody>
              <div>
                {card_body}
              </div>                            
            </ModalBody>
            <ModalFooter>
              {savingButton}{' '}
              {cancelButton}
            </ModalFooter>            
          </Modal>
        </div>
    ); 
  }
}

export default DatesPicker;